"use client";
import React, { useEffect } from "react";
import LoginForm from "./Components/LoginForm";
import Header from "@/components/shared/Header";
import { getCookie } from "cookies-next";
import { useRouter } from "next/navigation";
// import { Metadata } from "next";

// export const metadata: Metadata = {
//   title: "تسجيل الدخول",
// };

const page = () => {
  const router = useRouter();
  useEffect(() => {
    const token = getCookie("seveezToken");
    const tokenTwo = getCookie("Education_User_token");
    if (token && tokenTwo && token === tokenTwo) {
      router.push("/dashboard");
    }
  }, []);
  return (
    <div>
      <Header />
      <div className=" flex items-center justify-center h-[calc(100vh-80px)]">
        <LoginForm />
      </div>
    </div>
  );
};

export default page;
